
.InovuaReactDataGrid__row {
    position: relative;
}
/* .@inovua/react-observer-placeholder{

} */
/* div[style*="background: rgb(225, 101, 101); color: black; padding: 1.5rem; font-size: 1.25rem; position: absolute; top: 0px; left: 0px; z-index: 100000; width: 100%; display: flex; align-items: center; justify-content: center;"] {
  display: none !important;
}
.rd-row-detail-expander {
  display: none !important;
} */
.InovuaReactDataGrid__row::after {
  content: "";
  position: absolute;
  bottom: 0; /* Adjust the distance between the top of the element and the double dashed line as needed */
  left: 0;
  width: 100%;
  height: 1px; /* Adjust the space between the two lines (doubled) */
  background: repeating-linear-gradient(
    to right,
    #ddd,
    #ddd 10px, /* Adjust the space between the two lines (doubled) */
    transparent 10px,
    transparent 20px /* Adjust the space between the two lines (doubled) */
  );
}
/* .InovuaReactDataGrid__row--first{
  border:none !important ;
  background:none !important
} */
/* .InovuaReactDataGrid__row--first::after{
  background:none !important
}

.InovuaReactDataGrid__row--selected {
  background:none !important;
  border:none !important;
}
.InovuaReactDataGrid--focused {
  background:none !important;
  border:none !important;
}
.InovuaReactDataGrid__row--selected {
  border-color: transparent !important;
} */

/* Remove border color when the cell is focused */
 /*
.InovuaReactDataGrid__cell--focused {
  outline: none !important;
  border-color: transparent !important;
}.InovuaReactDataGrid__cell-content {
  white-space: normal !important;
}

.InovuaReactDataGrid__box--ellipsis, .InovuaReactDataGrid__cell__content, .InovuaReactDataGrid__header-group__title{

  white-space: normal !important;
}.InovuaReactDataGrid__column-header__content {

  white-space: nowrap !important;
} */
/* .InovuaReactDataGrid__header-layout{
 border-color:  #EBEBEB !important; 
}
.InovuaReactDataGrid--theme-default-light{ 
  border-color:  #EBEBEB !important; 
} */
/* .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__cell:not(.InovuaReactDataGrid__cell--no-padding), 

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__footer-row-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-group__title, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__locked-row-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-cell, .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__nested-group-cell{
  padding: 12px !;
} */
/* .InovuaReactDataGrid__column-header__resize-wrapper{
  flex-direction: column-reverse;
}
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__filter-wrapper{
  border-top: none;
} */
/* .InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content{
  font-weight: 500;
  color:#838383;
  background-color: #F6F6F6 !important;
  font-size: 0.9rem;
} */
/* .InovuaReactDataGrid__cell, .InovuaReactDataGrid__footer-row-cell, .InovuaReactDataGrid__locked-row-cell{
    padding:10px 10px!important;
color:black;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1.53125rem;
} */
/* .inovua-react-toolkit-load-mask.inovua-react-toolkit-load-mask--theme-default-light .inovua-react-toolkit-load-mask__loader--svg .inovua-react-toolkit-load-mask__loader-spinner{
  background-image: conic-gradient(from 90deg,#e3e3e3,black .99turn,#e3e3e3) !important;
}
.InovuaReactDataGrid__row-active-borders{
 display: none !important;
}
.inovua-react-toolkit-combo-box__list{
  text-transform: capitalize;
}
.inovua-react-toolkit-combo-box__value {

  text-transform: capitalize;
}.inovua-react-toolkit-combo-box--focus{
  text-transform: capitalize;
}.inovua-react-toolkit-combo-box__input__wrapper{
  text-transform: capitalize;
}
.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__row:not(.InovuaReactDataGrid__row--show-vertical-borders) .InovuaReactDataGrid__row-details{
  position: relative;
} */
.MuiDataGrid-columnHeaders{
 height: 48px !important;
 min-height: 48px !important;
 max-height: 48px !important;
 border-radius: 0 !important;

  background-color: #F6F6F6 !important;
}
.MuiDataGrid-columnHeadersInner{
  /* width: 100% !important; */
  flex:8 !important;
  min-width: fit-content !important;
  border: none !important;
  height: 48px!important;
  font-weight: 500;
  color:#838383!important; 
  /* #2C2D33  !important; */
  background-color: #F6F6F6 !important;
  font-size: 0.9rem;
  border-bottom: 1px solid #EBEBEB !important;
  
}
.MuiDataGrid-footerContainer{
  min-height: 32px !important;
}
.MuiToolbar-root .MuiToolbar-gutters{
  min-height: 30px !important;
}
.MuiDataGrid-virtualScroller{
  overflow-y: auto !important;
  overflow-x: auto !important;
  height: calc(100% - 48px) !important;
  /* min-width: fit-content !important; */
}
.MuiTablePagination-actions .MuiButtonBase-root{
  padding:0 !important
}
.css-yrdy0g-MuiDataGrid-columnHeaderRow{

  width: 100% !important;
  justify-content: space-between;
  display: flex;
  /* gap:10px */
}
.MuiDataGrid-virtualScrollerRenderZone{
  width: 100%;
}
.MuiDataGrid-root{
  border: none !important;
  height:100% !important
}
.MuiDataGrid-iconSeparator{
  display: none !important;
}
.MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus{
  border: none !important;
  outline: none !important;
}.MuiDataGrid-columnHeader:hover, .MuiDataGrid-cell:hover{
  border: none !important;
  outline: none !important;
}
.MuiDataGrid-columnHeader{
  height: 48px !important;  white-space: normal !important;
}
.all-content{
  color:black;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.53125rem;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px;
  border: 2px solid rgb(232, 227, 227);

  background-color: rgb(232, 227, 227);
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background-color: #838383;
  background-clip: padding-box;
  border-radius: 15px;
  height: 1px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #838383;
}

::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
  height: 5px; /* width of the scrollbar */  border-radius: 15px;
}


::-webkit-scrollbar-thumb:hover {
  background: #838383; /* color of the scrollbar on hover */
}
.MuiDataGrid-overlayWrapperInner{
  min-height:calc(100vh - 400px) !important ;
  overflow: hidden;
}